const CustomAudioPlayer = ({ arrayBuffer }) => {
    const audioBlob = new Blob([arrayBuffer], { type: 'audio/wav' });
    const dataUri = URL.createObjectURL(audioBlob);

  return (
    <div>
      {dataUri && <audio controls src={dataUri} />}
    </div>
  );
};

export default CustomAudioPlayer;
