import { SpeechConfig, SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk'

export async function synthesizeSpeech(text, languageCode, languageVoice, submit, precheck) {
  const speechConfig = SpeechConfig.fromSubscription("6bb424c943ad4bdcbf1994ad5a9528f7", "eastus");
  speechConfig.speechSynthesisLanguage = languageCode;
  speechConfig.speechSynthesisVoiceName = languageVoice;
  const speechSynthesizer = new SpeechSynthesizer(speechConfig, null);

  return new Promise((resolve, reject) => {
    submit({ precheck: true })
      .then(({ isUpgradeRequired }) => {
        if (!isUpgradeRequired) {
          speechSynthesizer.speakTextAsync(
            text,
            result => {
              speechSynthesizer.close();
              submit(Object.assign(result, { text, languageCode, languageVoice }))
              resolve(result.audioData);
            },
            error => {
              console.error(error);
              speechSynthesizer.close();
              reject(error);
            }
          );
        } else {
          resolve({ isUpgradeRequired });
        }
      })
  });
};