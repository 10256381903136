import languagesData from './languages.json'
import { useState } from "react";
import DownloadButton from './DownloadButton';
import CustomAudioPlayer from './AudioPlayer';
import { synthesizeSpeech } from "./SpeechSynthesisBrowser";
import { CustomModalLayout, Layout, Cell, Heading, Box, FormField, InputArea, Button, Dropdown, Text, Page } from '@wix/design-system'
import * as Icons from '@wix/wix-ui-icons-common';

const Form = ({ isUpgraded, instanceData, submit }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [voiceList, setVoiceList] = useState([{ id: 0, value: 'Please select language first' }]);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const languagesDataList = languagesData.languages;
    const languagesList = languagesDataList.map((language, index) => ({
        id: index,
        value: language.language
    }));
    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.id);
        const getVoicesById = (id) => {
            const language = languagesDataList.find(language => language.id === id);
            if (language) {
                const voicesList = language.voices.map((voice, index) => ({
                    id: index,
                    value: voice
                }));
                return voicesList;
            }
            return null;
        };
        const voices = getVoicesById(e.id);
        setVoiceList(voices);
    };
    const handleVoiceChange = (e) => {
        setSelectedVoice(e.id);
    }
    const [input, setInput] = useState('');
    const [inputStatus, setInputStatus] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const [arrayBuffer, setArrayBuffer] = useState(null);
    const handleInputChange = (e) => {
        setInput(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // var t = e.target.querySelector('textarea').value;
        setArrayBuffer(null);
        if (input == '' || input === null) {
            setInputStatus('error');
            setStatusMessage('Please enter some text!');
            return;
        };
        if (selectedLanguage !== null && selectedVoice !== null) {
            const language = languagesDataList.find(language => language.id == selectedLanguage);
            const languageCode = language.code;
            const languageVoice = language.voices[selectedVoice];
            const voice = languageVoice.split(' ');
            const response = await synthesizeSpeech(input, languageCode, voice[0], submit);
            if (response?.isUpgradeRequired === true) {
                window.open(`https://www.wix.com/apps/upgrade/${"788ab384-2408-49ee-b4e9-e258ce2c2608"}?appInstanceId=${(instanceData)?.instance?.instanceId}`, '_blank');
            } else {
                setArrayBuffer(response);
            }
        } else {
            const response = await synthesizeSpeech(input, "en-US", "en-US-JennyNeural", submit);
            if (response?.isUpgradeRequired === true) {
                window.open(`https://www.wix.com/apps/upgrade/${"788ab384-2408-49ee-b4e9-e258ce2c2608"}?appInstanceId=${(instanceData)?.instance?.instanceId}`, '_blank');
            } else {
                setArrayBuffer(response);
            }
        };
    };
    return (
        <Page>
            <Page.Header title="AI Voice: Feel Personal"
                actionsBar={<Button
                    as='a'
                    href={`https://www.wix.com/apps/upgrade/${"788ab384-2408-49ee-b4e9-e258ce2c2608"}?appInstanceId=${(instanceData)?.instance?.instanceId}`}
                    target='_blank'
                    prefixIcon={<Icons.PremiumFilled />} skin={
                        isUpgraded ? "premium-light" : "premium"
                    }>
                    {isUpgraded ? "Manage Plan" : "Upgrade"}
                </Button>}>
            </Page.Header>
            <Page.Content>
                <Layout>
                    <Cell span={12}>
                        {inputStatus !== null ?
                            <FormField label='Input Text' status={inputStatus} statusMessage={statusMessage}>
                                <InputArea
                                    minHeight="200px"
                                    placeholder="Enter your text here..."
                                    rows={4}
                                    hasCounter
                                    resizable
                                    onChange={handleInputChange}
                                    value={input}
                                />
                            </FormField>
                            :
                            <FormField label='Input Text'>
                                <InputArea
                                    minHeight="200px"
                                    placeholder="Enter your text here..."
                                    rows={4}
                                    hasCounter
                                    resizable
                                    onChange={handleInputChange}
                                    value={input}
                                />
                            </FormField>}
                    </Cell>
                    <Cell span={5}>
                        <FormField label='Select language for the audio'>
                            <Dropdown onSelect={handleLanguageChange}
                                selectedId={selectedLanguage}
                                placeholder="Select language" options={languagesList} />
                        </FormField>
                    </Cell>
                    <Cell span={5}>
                        <FormField label='Select voice for the audio'>
                            <Dropdown
                                onSelect={handleVoiceChange}
                                options={voiceList}
                                selectedId={selectedVoice}
                                placeholder='Select voice' />
                        </FormField>
                    </Cell>
                    <Cell span={4}>
                        <Box align="center" padding="SP2">
                            {
                                arrayBuffer !== null &&
                                <CustomAudioPlayer arrayBuffer={arrayBuffer} />
                            }
                        </Box>
                    </Cell>
                    <Cell span={4}>
                        <Box padding="SP2">
                            {
                                arrayBuffer !== null &&
                                <DownloadButton arrayBuffer={arrayBuffer} />
                            }
                        </Box>
                    </Cell>
                    <Cell span={12}>
                        <Page.Footer divider>
                            <Page.Footer.End>
                                <Box gap="SP2">
                                    <Button onClick={() => {
                                        setArrayBuffer(null); setInput('');
                                        setSelectedLanguage(null);
                                        setSelectedVoice(null);
                                    }}
                                        skin="light">Clear</Button>
                                    <Button onClick={(e) => { handleSubmit(e); }}>Generate</Button>
                                </Box>
                            </Page.Footer.End>
                        </Page.Footer>
                    </Cell>
                </Layout>
            </Page.Content>
        </Page>
    );
};

export default Form;