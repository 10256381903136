import React from 'react';
import {Button} from '@wix/design-system';

const DownloadButton = ({ arrayBuffer }) => {
  const handleDownloadClick = () => {
    // Convert the ArrayBuffer to a WAV Blob
    const wavBlob = new Blob([arrayBuffer], { type: 'audio/wav' });

    // Create a data URI for the Blob
    const dataUri = URL.createObjectURL(wavBlob);

    // Create a temporary anchor element
    const downloadLink = document.createElement('a');

    // Set the href attribute of the anchor to the data URI
    downloadLink.href = dataUri;

    // Set the download attribute to specify the file name
    downloadLink.download = 'ai-voice.wav';

    // Append the anchor to the document
    document.body.appendChild(downloadLink);

    // Trigger a click on the anchor to start the download
    downloadLink.click();

    // Remove the anchor from the document
    document.body.removeChild(downloadLink);
  };

  return (
    <Button onClick={() => {handleDownloadClick();}} skin="dark" fullWidth>Download Audio</Button>
  );
};

export default DownloadButton;
