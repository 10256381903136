import Form from './FormComponent';
import "./FormComponentStyles.css"
import "./DownloadButtonStyles.css"
import { BrowserRouter } from 'react-router-dom';
import { WixDesignSystemProvider } from '@wix/design-system';
import '@wix/design-system/styles.global.css';
import { ReactTagManager } from 'react-gtm-ts';
import React from "react";
import { Page, Box, Loader } from '@wix/design-system';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}
function App() {
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance');
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });

  React.useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, [])

  const BASE_URL = `https://certifiedcode.editorx.io/ai-voice/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
      .catch(() => {

      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {

      })
  }

  function submit(data) {
    return fetch(BASE_URL + "/generations", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Authorization": instance || ""
      }
    })
    .then(response => {
      return response.json()
    })
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=788ab384-2408-49ee-b4e9-e258ce2c2608&redirectUrl=https://certifiedcode.editorx.io/ai-voice/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }


  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <BrowserRouter>
        <Form isUpgraded={isUpgraded} instanceData={instanceData} submit={submit} />
      </BrowserRouter>
    </WixDesignSystemProvider>
  );
}

export default App;
